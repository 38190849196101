import React, { useState } from "react";
import classes from "./AuthModal.module.css";
import { InputText } from "primereact/inputtext";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { toast } from "react-toastify";
import { SignInApi, SignUpApi } from "../../Api/api";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loaders/Loader";

const AuthModal = ({ handleCloseModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msisdn, setMsisdn] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const controlsForContainer1 = useAnimation();
  const controlsForContainer2 = useAnimation();

  const controlsForContainer3 = useAnimation();
  const controlsForContainer4 = useAnimation();

  const containerVariants = {
    hidden: {
      opacity: 0,
      display: "none",
    },
    visible: {
      opacity: 1,
      display: "flex",
    },
  };

  const handleSignUpClick = () => {
    setMsisdn("");
    setPassword("");
    controlsForContainer1.start("hidden");
    controlsForContainer3.start("hidden");
    setTimeout(() => {
      controlsForContainer2.start("visible");
      controlsForContainer4.start("visible");
    }, 500);
  };

  const handleSignInClick = () => {
    setMsisdn("");
    setPassword("");
    controlsForContainer2.start("hidden");
    controlsForContainer4.start("hidden");
    setTimeout(() => {
      controlsForContainer1.start("visible");
      controlsForContainer3.start("visible");
    }, 500);
  };

  const signinHandler = async (e) => {
    e.preventDefault();

    if (!msisdn || msisdn == "" || msisdn.trim().length <= 0) {
      toast.error("Please Fill the Sign in Details!");
      return;
    }
    try {
      setLoading(true);
      const response = await SignInApi({ msisdn, password });
      setLoading(false);
      dispatch(setUser(response?.data?.result));
      handleCloseModal();
      navigate("/app");
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const signupHandler = async (e) => {
    e.preventDefault();

    if (!msisdn || msisdn == "" || msisdn.trim().length <= 0) {
      toast.error("Please Fill the Sign up Details!");
      return;
    }
    try {
      setLoading(true);
      const response = await SignUpApi({ msisdn, password });
      setLoading(false);
      dispatch(setUser(response?.data?.result));
      handleCloseModal();
      navigate("/app");
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  return (
    <motion.div
      className={classes.modal}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        ease: "linear",
      }}
    >
      <div className={classes.container}>
        <button className={classes.close_btn} onClick={handleCloseModal}>
          <i className={`pi pi-times ${classes.close_icon}`}></i>
        </button>

        <motion.div
          initial="visible"
          variants={containerVariants}
          animate={controlsForContainer1}
          className={classes.signin}
          transition={{ duration: 0.5, ease: "linear" }}
        >
          <div className={classes.signin_header}>
            <p className={classes.signin_text}>
              Sign In and Start enjoying Panz AI
            </p>
          </div>
          <form className={classes.form} onSubmit={signinHandler}>
            <InputText
              type="text"
              keyfilter="int"
              className={`p-inputtext-lg ${classes.input}`}
              placeholder="Enter Your Mobile Number"
              onChange={(e) => setMsisdn(e.target.value)}
              value={msisdn}
            />
            <InputText
              type="password"
              className={`p-inputtext-lg ${classes.input}`}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button className={classes.btn} type="submit" disabled={loading}>
              {loading ? <Loader /> : "Sign In"}
            </button>
          </form>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controlsForContainer2}
          transition={{
            duration: 0.5,
            ease: "linear",
          }}
          className={classes.signin}
        >
          <div className={classes.signin_header}>
            <p className={classes.signin_text}>
              Sign Up and Start enjoying Panz AI
            </p>
          </div>
          <form className={classes.form} onSubmit={signupHandler}>
            <InputText
              type="text"
              keyfilter="int"
              className={`p-inputtext-lg ${classes.input}`}
              placeholder="Enter Your Mobile Number"
              onChange={(e) => setMsisdn(e.target.value)}
              value={msisdn}
            />
            <InputText
              type="password"
              className={`p-inputtext-lg ${classes.input}`}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <button className={classes.btn} type="submit" disabled={loading}>
              {loading ? <Loader /> : "Sign Up"}
            </button>
          </form>
        </motion.div>

        {/* <motion.div
          className={classes.signup}
          initial="visible"
          animate={controlsForContainer3}
          variants={containerVariants}
          transition={{
            duration: 0.5,
            ease: "linear",
          }}
        >
          <div className={classes.signup_header}>
            <p className={classes.text_primary}>Hey, new friend!</p>
            <p className={classes.text_secondary}>
              New to the Panz AI? Sign up and start your journey
            </p>
            <button
              className={classes.signup_btn}
              type="button"
              onClick={handleSignUpClick}
            >
              Sign Up
            </button>
          </div>
        </motion.div> */}

        <motion.div
          className={classes.signup}
          initial="visible"
          animate={controlsForContainer3}
          variants={containerVariants}
          transition={{
            duration: 0.5,
            ease: "linear",
          }}
        >
          <div className={classes.signup_header}>
            <p className={classes.text_primary}>Hey, Friend!</p>
            <p className={classes.text_secondary}>
              Welcome Back!, Sign In and Start enjoying the Panz AI
            </p>
            {/* <button
              className={classes.signup_btn}
              type="button"
              onClick={handleSignUpClick}
            >
              Sign Up
            </button> */}
          </div>
        </motion.div>

        <motion.div
          className={classes.signup}
          initial="hidden"
          animate={controlsForContainer4}
          variants={containerVariants}
          transition={{
            duration: 0.5,
            ease: "linear",
          }}
        >
          <div className={classes.signup_header}>
            <p className={classes.text_primary}>Already a User!</p>
            <p className={classes.text_secondary}>
              Sign in to the Panz AI.
            </p>
            <button
              className={classes.signup_btn}
              type="button"
              onClick={handleSignInClick}
            >
              Sign In
            </button>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default AuthModal;
