import React, { useState } from "react";
import classes from "./Navbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../slices/userSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { openDrawer } from "../slices/drawerSlice";
import Drawer from "./Drawer";

const Navbar = ({ chatHistoryLoading, chatHistory, chat_id }) => {
  const [dropdown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { drawer } = useSelector((state) => state?.drawerSlice);

  const dropdownHandler = () => {
    setDropdown((prevValue) => !prevValue);
  };
  const logoutHandler = () => {
    dispatch(logoutUser());
    toast.success("Logout Successfully...");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const handleOpenDrawer = () => {
    dispatch(openDrawer());
  };

  return (
    <>
      <nav className={classes.nav}>
        <div className={classes.logo_container}>
          <button
            className={classes.nav_menu}
            type="button"
            onClick={() => handleOpenDrawer()}
          >
            <i className={`pi pi-bars ${classes.nav_menu_icon}`}></i>
          </button>
          <p className={classes.brand_name} onClick={() => navigate("/app")}>
            Panz AI
          </p>
        </div>
        <div
          className={classes.profile_info}
          type="button"
          onClick={() => dropdownHandler()}
        >
          <img
            src="/assets/images/profile-avatar.png"
            alt="profile avatar"
            className={classes.profile_avatar}
          />
          <i
            className={`pi ${dropdown ? "pi-chevron-up" : "pi-chevron-down"} ${
              classes.arrow_down
            }`}
          ></i>

          <div
            className={`${classes.dropdown_menu} ${dropdown && classes.active}`}
          >
            <div className={classes.menu_list}>
              <button
                className={classes.list_item_btn}
                onClick={() => navigate("/profile")}
              >
                My Profile
              </button>
              <button
                className={classes.list_item_btn_logout}
                onClick={() => logoutHandler()}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </nav>

      <Drawer
        drawer={drawer}
        chatHistory={chatHistory}
        chatHistoryLoading={chatHistoryLoading}
        chat_id={chat_id}
      />
    </>
  );
};

export default Navbar;
