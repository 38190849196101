import React, { useEffect, useState } from "react";
import classes from "./HomePage.module.css";
import { motion, useAnimation } from "framer-motion";
import AuthModal from "../Components/Modal/AuthModal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const HomePage = () => {
  const { user } = useSelector((state) => state?.userSlice);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [user]);

  const [signinModal, setSigninModal] = useState(false);

  const [message, setMessage] = useState([
    {
      id: 1,
      user_message: "Tell me a joke in English.",
      ai_reply:
        "Why did the scarecrow win an award? Because he was outstanding in his field!",
    },
    {
      id: 2,
      user_message: "Who won the 2024 t20 cricket world cup?",
      ai_reply: "India won the 2024 ICC Men's T20 World Cup.",
    },
  ]);

  const [questions, setQuestions] = useState([
    {
      id: 1,
      question: "Tell me a fun fact about space?",
    },
    {
      id: 2,
      question: "What's the difference between a compiler and an interpreter?",
    },
    {
      id: 3,
      question: "How do you make a perfect cup of coffee?",
    },
    {
      id: 4,
      question:
        "Can you explain the concept of quantum computing in simple terms?",
    },
    {
      id: 5,
      question: "What's the history behind the internet?",
    },
    {
      id: 6,
      question: "How does blockchain technology work?",
    },
    {
      id: 7,
      question: "Can you recommend a good book on personal development?",
    },
    {
      id: 8,
      question:
        "How do different programming paradigms like functional and object-oriented differ?",
    },
  ]);

  const [questions2, setQuestions2] = useState([
    {
      id: 9,
      question: "What's a unique tradition from your country?",
    },
    {
      id: 10,
      question:
        "How does machine learning differ from traditional programming?",
    },
    {
      id: 11,
      question: "What are some common myths about nutrition?",
    },
    {
      id: 12,
      question: "Can you explain how a light bulb works?",
    },
    {
      id: 13,
      question: "What are the key principles of effective time management?",
    },
    {
      id: 14,
      question: "How does the stock market work?",
    },
    {
      id: 15,
      question: "Can you give an overview of the major world religions?",
    },
    {
      id: 16,
      question: "What's the significance of the theory of relativity?",
    },
  ]);

  const [questions3, setQuestions3] = useState([
    {
      id: 17,
      question: "What's the impact of social media on communication?",
    },
    {
      id: 18,
      question: "How do renewable energy sources like solar and wind work?",
    },
    {
      id: 19,
      question: "What are the benefits of learning a new language?",
    },
    {
      id: 20,
      question: "Can you explain the concept of supply and demand?",
    },
    {
      id: 21,
      question: "What are the main causes of climate change?",
    },
    {
      id: 22,
      question: "How does the human brain process emotions?",
    },
    {
      id: 23,
      question: "What are the different types of ecosystems?",
    },
    {
      id: 24,
      question: "How do advancements in technology impact education?",
    },
  ]);

  const [index, setIndex] = useState(0);

  const textVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  const messageBoxVariants = {
    boxhidden: {
      opacity: 0,
      display: "none",
    },
    boxvisible: {
      opacity: 1,
      display: "flex",
    },
  };

  const controlsForText = useAnimation();
  const controlsForBox = useAnimation();

  const controlsForSecondText = useAnimation();
  const controlsForSecondBox = useAnimation();

  useEffect(() => {
    const animateMessages = async () => {
      if (location.pathname == "/") {
        try {
          await controlsForText.start("visible").then(() => {
            setTimeout(() => {
              if (location.pathname == "/") {
                setTimeout(async () => {
                  try {
                    controlsForSecondText.start("visible");
                  } catch (error) {
                    console.log(error);
                  }
                }, 1000);
              }
            }, [1500]);

            if (location.pathname == "/") {
              setTimeout(async () => {
                if (location.pathname == "/") {
                  try {
                    controlsForSecondText.start("hidden");
                  } catch (error) {
                    console.log(error);
                  }
                  try {
                    controlsForText.start("hidden");
                  } catch (error) {
                    console.log(error);
                  }
                }
                setTimeout(() => {
                  setIndex((prevValue) => (prevValue === 0 ? 1 : 0));
                }, 1000);
              }, 8000);
            }
          });
        } catch (error) {
          console.log(error, "error");
        }
      }
    };

    const intervalId = setInterval(() => {
      if (location.pathname == "/") {
        animateMessages();
      }
    }, 15000);

    if (location.pathname == "/") {
      animateMessages();
    }
    return () => clearInterval(intervalId);
  }, [
    location,
  ]);

  const handleSigninClick = () => {
    setSigninModal(true);
  };

  const handleCloseModal = () => {
    setSigninModal(false);
  };

  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (event) => {
    setStartPosition({ x: event.clientX, y: event.clientY });
    setIsDragging(false);
  };

  const handleMouseUp = (event, data) => {
    const distance = Math.sqrt(
      Math.pow(event.clientX - startPosition.x, 2) +
        Math.pow(event.clientY - startPosition.y, 2)
    );
    if (distance < 5 && !isDragging) {
      // handleSigninClick();
      navigate("/demo")
    }
  };

  const handleMouseMove = () => {
    setIsDragging(true);
  };

  return (
    <>
      <div className={classes.container}>
        <nav className={classes.nav}>
          <div className={classes.logo_container}
       
          >
            <img
              src="./assets/images/ai-logo.png"
              alt="Panz ai"
              className={classes.logo}
            />
            <p className={classes.brand}>AI</p>
          </div>
          <div className={classes.nav_sub_container}>
            {/* <p className={classes.link}>FAQ's</p> */}
            <button
              type="button"
              className={classes.sign_in_btn}
              onClick={handleSigninClick}
            >
              Sign in
            </button>
          </div>
        </nav>

        <main className={classes.main_landing_page}>
          <div className={classes.section_intro}>
            <div className={classes.brand_intro}>
              <p className={classes.brand_main_text}>Panz AI</p>
              <p className={classes.brand_sub_text}>
                Supercharge your creativity and productivity
              </p>
              <p className={classes.brand_initiative}>
                Chat to start growing, learning and more with Panz AI.
              </p>

              <div className={classes.buttons_wrapper}>
              <button
                className={classes.brand_sign_in_btn}
                onClick={handleSigninClick}
              >
                Sign In
              </button>
              <button
                className={`${classes.brand_sign_in_btn} ${classes.demo_btn}`}
                onClick={()=>navigate("/demo")}
              >
                Try Demo
              </button>
              </div>
         
            </div>
          </div>

          <div className={classes.section_demo}>
            <motion.div
              className={classes.ai_prompt_box}
              variants={messageBoxVariants}
              initial="boxVisible"
              animate={controlsForBox}
              transition={{ duration: 0.5, ease: "linear" }}
            >
              <div className={classes.ai_message_box}>
                <img
                  src="./assets/images/avatar.png"
                  alt="user avatar"
                  className={classes.avatar}
                />
                <motion.p
                  className={classes.user_message}
                  initial="hidden"
                  variants={textVariants}
                  animate={controlsForText}
                  transition={{
                    staggerChildren: 0.05,
                    type: "spring",
                    damping: 10,
                    stiffness: 50,
                  }}
                >
                  {message[index]?.user_message
                    ?.split(" ")
                    .map((word, index) => (
                      <span className={classes.span} key={index}>
                        {word?.split("").map((char, idx) => {
                          return (
                            <motion.span
                              key={idx}
                              variants={textVariants}
                              className={classes.span}
                            >
                              {char}
                            </motion.span>
                          );
                        })}
                        <span className={classes.span}>&nbsp;</span>
                      </span>
                    ))}
                </motion.p>
              </div>
              <motion.div
                className={classes.ai_reply_box}
                variants={messageBoxVariants}
                // initial="boxhidden"
                initial="boxvisible"
                animate={controlsForSecondBox}
                transition={{ duration: 0.5, ease: "linear" }}
              >
                <img
                  src="./assets/images/ai-logo.png"
                  alt="ai-logo"
                  className={classes.avatar}
                />
                <motion.p
                  className={classes.ai_message}
                  initial="hidden"
                  variants={textVariants}
                  animate={controlsForSecondText}
                  transition={{
                    staggerChildren: 0.05,
                    type: "spring",
                    damping: 10,
                    stiffness: 50,
                  }}
                >
                  {message[index]?.ai_reply.split(" ").map((word, index) => (
                    <span className={classes.span} key={index}>
                      {word.split("").map((char, idx) => {
                        return (
                          <motion.span
                            key={idx}
                            variants={textVariants}
                            className={classes.span}
                          >
                            {char}
                          </motion.span>
                        );
                      })}
                      <span className={classes.span}>&nbsp;</span>
                    </span>
                  ))}
                </motion.p>
              </motion.div>
            </motion.div>
          </div>
        </main>

        <div className={classes.section_questions}>
          <p className={classes.section_questions_heading}>Ask Me Questions.</p>
          <motion.div
            drag="x"
            dragConstraints={{
              top: 0,
              left: -1900,
              right: 0,
              bottom: 0,
            }}
            className={classes.section_drag_questions}
            transition={{
              type: "spring",
              damping: 100,
              stiffness: 100,
            }}
            onMouseDown={handleMouseDown}
            onMouseUp={(event) => handleMouseUp(event)}
            onMouseMove={handleMouseMove}
          >
            <div className={classes.question_grid_1}>
              {questions.map((question, idx) => {
                return (
                  <div className={classes.question_container} key={idx}>
                    <p className={classes.question}>{question?.question}</p>
                  </div>
                );
              })}
            </div>

            <div className={classes.question_grid_2}>
              {questions2.map((question, idx) => {
                return (
                  <div className={classes.question_container} key={idx}>
                    <p className={classes.question}>{question?.question}</p>
                  </div>
                );
              })}
            </div>

            <div className={classes.question_grid_3}>
              {questions3.map((question, idx) => {
                return (
                  <div className={classes.question_container} key={idx}>
                    <p className={classes.question}>{question?.question}</p>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>

        <footer className={classes.footer_section}>
          <div className={classes.footer}>
            <p className={classes.copyright}>
              Panz{" "}
              &copy;
              {new Date().getFullYear()}
              {/* <i className={`fa-solid fa-copyright ${classes.icon}`}></i> 2024 */}
            </p>
            <p className={classes.terms_and_conditions}
            onClick={()=>navigate("/terms")}
            >Terms & Conditions</p>
          </div>
        </footer>
      </div>
      {signinModal && <AuthModal handleCloseModal={handleCloseModal} />}
    </>
  );
};

export default HomePage;
