import React, { useEffect, useState } from "react";
import classes from "./FreeChatDemoPage.module.css";
import UserLocation from "../Components/UserLocation";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FreeChatAiApi, RandomQuestionsApi } from "../Api/api";
import AuthModal from "../Components/Modal/AuthModal";
import CategoryDragSection from "../Components/Category-Section/CategoryDragSection";
import { setFreeChatDate, setFreeUserChat } from "../slices/freeChatSlice";
import ChatBox from "../Components/Chat-Box/ChatBox";
import SmallLoader from "../Components/Loaders/SmallLoader";
import ChatVoiceModal from "../Components/Chat-Box/ChatVoiceModal";

const FreeChatDemoPage = () => {
  const { expand } = useSelector((state) => state?.sidebarSlice);
  const dispatch = useDispatch();

  const [locationPermission, setLocationPermission] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [signinModal, setSigninModal] = useState(false);
  const [message, setMessage] = useState("");
  const [info, setInfo] = useState(false);
  //   const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

  const { chat: messages } = useSelector(
    (state) => state?.persisted.freeChatSlice
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(setFreeChatDate());
    }, 10000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  useEffect(() => {
    if (messages.length >= 5) {
      setInfo(true);
    }
  }, [messages]);

  useEffect(() => {
    dispatch(setFreeChatDate());
    fetchRandomQuestions();
  }, []);

  const fetchRandomQuestions = async () => {
    try {
      setQuestionsLoading(true);
      const response = await RandomQuestionsApi();
      setQuestionsLoading(false);
      setQuestions(response?.data?.result);
    } catch (error) {
      setQuestionsLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const handleSigninClick = () => {
    setSigninModal(true);
  };

  const handleCloseModal = () => {
    setSigninModal(false);
  };

  const navigateHandler = (path) => {
    navigate(path);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleMessageSubmit(e);
    }
  };

  const [chatVoiceModal, setChatVoiceModal] = useState(false);
  const [aiChatLoading, setAiChatLoading] = useState(false);

  const handleMessageSubmit = async (e, query) => {
    e.preventDefault();

    if (messages.length >= 5) {
      toast.error(
        "You've reached your daily limit of 5 demo questions. Please return tomorrow for more."
      );
      return;
    }
    setAiChatLoading(true);
    if (query) {
      dispatch(
        setFreeUserChat({
          request: JSON.stringify(query),
          response: "",
        })
      );
    } else {
      dispatch(
        setFreeUserChat({
          request: JSON.stringify(message),
          response: "",
        })
      );
    }

    setMessage("");

    try {
      const response = await FreeChatAiApi({
        message: query ? query : message,
        latitude: latitude,
        longitude: longitude,
        locationPermission: locationPermission,
      });

      console.log(response, "r");
      let newMessages = messages.slice(0, messages.length);

      dispatch(
        setFreeUserChat([
          ...newMessages,
          {
            request: JSON.stringify(response?.data?.result?.request),
            response: JSON.stringify(response?.data?.result?.response),
          },
        ])
      );
      setAiChatLoading(false);
      //   setMessages([...newMessages, response?.data?.result]);
    } catch (error) {
      setAiChatLoading(false);
      toast.error(
        error?.response?.data?.err ||
          error?.data?.err ||
          error?.message ||
          error
      );
    }
  };

  const onClickQuestionHandler = (e, question) => {
    setMessage(question);
    setTimeout(() => {
      handleMessageSubmit(e, question);
    }, 200);
  };

  console.log(messages, "m");

  return (
    <UserLocation
      setLatitude={setLatitude}
      setLongitude={setLongitude}
      setLocationPermission={setLocationPermission}
    >
      <div className={classes.container}>
        <nav className={classes.nav}>
          <div className={classes.logo_container} onClick={() => navigate("/")}>
            <img
              src="./assets/images/ai-logo.png"
              alt="Panz ai"
              className={classes.logo}
            />
            <p className={classes.brand}>AI</p>
          </div>
          <div className={classes.nav_sub_container}>
            {/* <p className={classes.link}>FAQ's</p> */}
            <button
              type="button"
              className={classes.sign_in_btn}
              onClick={handleSigninClick}
            >
              Sign in
            </button>
          </div>
        </nav>

        <div className={classes.chat_container}>
          {messages.length <= 0 ? (
            <div className={classes.chat_input_output_container}>
              <div className={classes.intro_section}>
                <p className={classes.intro}>Hello, Friend!</p>
                <p className={classes.intro_2}>How can I help you today?</p>
              </div>

              {questionsLoading ? (
                <div className={classes.loader_container}>
                  <SmallLoader />
                </div>
              ) : (
                <CategoryDragSection
                  data={questions}
                  dataItemKeys={{ imgurl: "", name: "" }}
                  category="questions"
                  onClickHandler={onClickQuestionHandler}
                  slice={true}
                />
              )}
            </div>
          ) : (
            <ChatBox
              aiChatLoading={aiChatLoading}
              chatLoading={false}
              messages={messages}
              showCategories={false}
              info={info}
              style={{
                maxHeight: "calc(100dvh - 80px - 10px - 20px - 110px)",
                minHeight: "calc(100dvh - 80px - 10px - 20px - 110px)",
              }}
            />
          )}

          <div className={classes.message_box_container}>
            <div className={classes.message_wrapper}>
              <button
                className={`${classes.voice_button} ${
                  (message != "" ||
                    message.length != 0 ||
                    message.trim().length != 0) &&
                  classes.inactive
                }`}
                onClick={() => setChatVoiceModal(true)}
                type="button"
                disabled={
                  message != "" ||
                  message.length > 0 ||
                  message.trim().length > 0
                }
              >
                <i className={`pi pi-microphone ${classes.arrow_up_icon}`}></i>
              </button>
              <textarea
                type="text"
                placeholder="Message Panz AI"
                className={classes.message_box}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                value={message}
              />
              <button
                className={`${classes.arrow_wrapper} ${
                  (message != "" ||
                    message.length != 0 ||
                    message.trim().length != 0) &&
                  classes.active
                }`}
                onClick={(e) => handleMessageSubmit(e, false)}
                // type="button"
                type="submit"
                disabled={
                  message == "" ||
                  message.length <= 0 ||
                  message.trim().length <= 0
                }
              >
                <i className={`pi pi-arrow-up ${classes.arrow_up_icon}`}></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ChatVoiceModal
        setChatVoiceModal={setChatVoiceModal}
        chatVoiceModal={chatVoiceModal}
        handleMessageSubmit={handleMessageSubmit}
      />
      {signinModal && <AuthModal handleCloseModal={handleCloseModal} />}
    </UserLocation>
  );
};

export default FreeChatDemoPage;
